<template>
  <div class="PrivacyPolicyPage">
    <div class="PrivacyPolicyPage__container">
      <p v-html="theme.privacyPolicy"></p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'PrivacyPolicyPage',
    computed: {
      ...mapGetters([
        'theme',
        'isAuthenticated',
        'authenticationError',
        'loadingAuth'
      ]),
    }
}
</script>

<style lang="scss" scoped>
.PrivacyPolicyPage{
  width: 100%;
  &__container{
  padding             : 20px 20px 20px 60px;
  display             : flex;
  flex-direction      : column;
  align-items         : flex-start;
  overflow-y: scroll;
  max-height: calc(100vh - 136px);
}
}
</style>
